<template>
  <b-overlay :show="is_busy" rounded="sm">
    <div class="container-fluid">
      <div class="text-center mb-2">
        <h2><strong>Result Sheet</strong></h2>
        <h5>
          {{ term }}<br>
          {{ session.name }} Academic Session<br>
          {{ room.name }}{{ room.form }}<br>
          <b-button variant="primary" size="sm" @click="editResult" v-if="is_edit==false">Edit</b-button>
          <b-button variant="primary" size="sm" @click="editResult" v-else>Save</b-button>
        </h5>
      </div>

      <div class="card">
        <div class="card-body" v-if="is_edit==false">
          <div class="table-responsive">
            <table class="table table-hover table-striped">    
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Handwriting</th>
                  <th>Verbal Fluency</th>
                  <th>Games</th>
                  <th>Construction</th>
                  <th>Musical</th>
                  <th>Punctiality</th>
                  <th>Alertness</th>
                  <th>Drawing</th>
                  <th>Neatness</th>
                  <th>Politeness</th>
                  <th>Honesty</th>
                  <th>Friendship</th>
                  <th>Self Control</th>
                  <th>Industrious</th>
                  <th>Generousity</th>
                  <th>Adjustment</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="result in req.results" :key="result.id">
                  <td> <span v-if="result.fullname">{{ result.fullname.lastname }} {{ result.fullname.firstname }} {{ result.fullname.middlename }}</span></td>
                  <td> {{ result.handwriting }}</td>
                  <td> {{ result.verbal_fluency }}</td>
                  <td> {{ result.games }}</td>
                  <td> {{ result.construction }}</td>
                  <td> {{ result.musical }}</td>
                  <td> {{ result.punctiality }}</td>
                  <td> {{ result.alertness }}</td>
                  <td> {{ result.drawing }}</td>
                  <td> {{ result.neatness }}</td>
                  <td> {{ result.politeness }}</td>
                  <td> {{ result.honesty }}</td>
                  <td> {{ result.friendship }}</td>
                  <td> {{ result.self_control }}</td>
                  <td> {{ result.industrious }}</td>
                  <td> {{ result.generousity }}</td>
                  <td> {{ result.adjustment }}</td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>
     
        <div class="card-body" v-else>
          <div v-for="result in req.results" :key="result.id">
            <div class="row border mb-2">
              <div class="col-md-12 my-2 text-center">
                <span v-if="result.fullname" class="">{{ result.fullname.lastname }} {{ result.fullname.firstname }} {{ result.fullname.middlename }}</span>
              </div>
              <div class="col-md-3 mb-2">
                <label>Hand Writing</label>
                <b-form-select v-model="result.handwriting" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Verbal Fluency</label>
                <b-form-select v-model="result.verbal_fluency" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Games</label>
                <b-form-select v-model="result.games" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Construction</label>
                <b-form-select v-model="result.construction" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Musical</label>
                <b-form-select v-model="result.musical" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Punctuality</label>
                <b-form-select v-model="result.punctiality" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Alertness</label>
                <b-form-select v-model="result.alertness" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Drawing</label>
                <b-form-select v-model="result.drawing" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Neatness</label>
                <b-form-select v-model="result.neatness" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Politeness</label>
                <b-form-select v-model="result.politeness" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Honesty</label>
                <b-form-select v-model="result.honesty" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Friendship</label>
                <b-form-select v-model="result.friendship" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Self Control</label>
                <b-form-select v-model="result.self_control" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Industrious</label>
                <b-form-select v-model="result.industrious" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Generousity</label>
                <b-form-select v-model="result.generousity" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-3 mb-2">
                <label>Adjustment</label>
                <b-form-select v-model="result.adjustment" :options="assessments"></b-form-select>
              </div>
              <div class="col-md-6 mb-2">
                <label>Teacher's Comment</label>
                <input v-model="result.teacher_comment" type="text" class="form-control">
              </div>
              <div class="col-md-6 mb-2">
                <label>Principal's Comment</label>
                <input v-model="result.principal_comment" type="text" class="form-control">
              </div>
              
              <input v-model="result.id" type="hidden" class="form-control">
            
            </div>
          </div>
        </div>
      </div>

    </div>
  </b-overlay>
</template>

<script>
  import axios from 'axios';
  import { Form } from 'vform';

  export default {
    created(){
      this.roleHelper(['1', '2', '5']);
      this.loadData();
    },
    
    data(){
      return{
        editmode: false,
        condiParam: {
          class_id: '',
          session_id: '',
          term_id: '',
        },
        req:{
          results: {},
        },
        term: '',
        session: '',
        room: '',
       
        assessments: [
          { text: 'Select', value: null }, 
          { text: 'A', value: 'A' }, 
          { text: 'B', value: 'B' }, 
          { text: 'C', value: 'C' }, 
          { text: 'D', value: 'D' }, 
          { text: 'E', value: 'E' }, 
          { text: 'F', value: 'F' }
        ],
        school: '',
        is_edit: false,
        is_busy: false,
      }
    },

    methods: {
      loadData(){
        this.condiParam.class_id = this.$route.params.class_id;
        this.condiParam.session_id = this.$route.params.session_id;
        this.condiParam.term_id = this.$route.params.term_id;

        if(!this.condiParam.class_id || !this.condiParam.session_id  || !this.condiParam.term_id)
        {
          Swal.fire(
            "Failed!",
            "Ops, One of the needed parameters are not set, try again.",
            "warning"
          );

          this.$router.push({path: '/result/add'});
        }

        if (this.is_busy) return;
        this.is_busy = true;

        axios.get("/result/psychomotor", { params: this.condiParam }) 
        .then(({data}) => {
            this.req.results = data.data.resultsheet;
            this.session = data.data.session;
            this.room = data.data.room;
            this.term = data.data.term;
        })
    
        .catch((err)=>{
            Swal.fire(
                "Error!",
                err.response.data.data.error,
                "error"
            );
        })

        .finally(() => {
            this.is_busy = false;
        });
      },

      editResult(){
        if(this.is_edit==true){
            this.is_edit = false;
            axios.post('/result/update/psychomotor', this.req)
            .then(()=>{
              Swal.fire(
                'Updated!',
                'Results Updated Successfully.',
                'success'
              )
              this.loadData();
            })
            .catch((err)=>{
              Swal.fire(
                "Error!",
                err.response.data.data.error,
                "error"
              );
            });
        }
        else {
          this.is_edit = true;
        }
      },

    },
  }
</script>

<style>
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: white;
    background-color: red;
    border-color: #dee2e6 #dee2e6 #f8fafc;
  }
</style>